<template>
  <v-data-table
    must-sort
    fixed-header
    :loading="loading"
    :headers="tableHeaders"
    :items="patients"
    :options.sync="options"
    :server-items-length="totalItems"
    :footer-props="{
      itemsPerPageOptions: [ 15, 50, 100, 200 ],
      itemsPerPageText: `${ $options.filters.capitalize($tc('patient', 2)) } ${ $t('per page') }:`,
      showFirstLastPage: true
    }"
    no-data-text="No patients found"
    class="elevation-2"
    item-key="uuid"
  >
    <template #top>
      <v-row
        justify="space-between"
        class="pt-3 px-3 mb-n3 "
      >
        <v-col md="3">
          <search-bar :value.sync="options.search" />
        </v-col>
        <v-spacer />
        <v-col md="2">
          <date-filter
            :value.sync="options.filter.dob"
            :label="$t('DOB')"
          />
        </v-col>
        <v-col md="2">
          <gender-filter
            :value.sync="options.filter.gender"
            :label="$tc('gender', 1) | capitalize"
            dense
            clearable
            multiple
          />
        </v-col>
      </v-row>
    </template>
    <template #item.last_name="{ item }">
      <span data-private>{{ item.last_name }}</span>
    </template>
    <template #item.first_name="{ item }">
      <span data-private>{{ item.first_name }}</span>
    </template>
    <template #item.gender="{ item }">
      <patient-gender
        :key="item.gender"
        :patient="item"
        data-private
        icon
      />
    </template>
    <template #item.dob="{ item }">
      {{ item.dob | moment('L') }}
    </template>
    <template #item.lab_results_count="{ item }">
      <router-link :to="{ name: 'Lab Results', query: { patient: item.uuid } }">
        {{ item.lab_results_count }}
      </router-link>
    </template>
    <template #item.prescriptions_count="{ item }">
      <router-link :to="{ name: 'Prescriptions', query: { patient: item.uuid } }">
        {{ item.prescriptions_count }}
      </router-link>
    </template>
  </v-data-table>
</template>
<script>
  export default {
    components: {
      SearchBar: () => import('@/components/tables/filters/SearchBar.vue'),
      PatientGender: () => import('@/components/patient/PatientGender.vue'),
      DateFilter: () => import('@/components/tables/filters/DateFilter.vue'),
      GenderFilter: () => import('@/components/tables/filters/GenderFilter.vue'),
    },
    metaInfo: {
      title: 'Patients',
    },
    props: {
      search: {
        type: String,
        default: null,
      },
      facility: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        loading: false,
        options: {
          search: null,
          sortBy: ['last_name'],
          sortDesc: [false],
          filter: this.$route.query,
        },
        totalItems: null,
        patients: [],
        dobPicker: false,
      }
    },
    computed: {
      headers () {
        return [
          {
            text: this.$t('surname'),
            value: 'last_name',
          },
          {
            text: this.$t('first name'),
            value: 'first_name',
          },
          {
            text: this.$options.filters.capitalize(this.$tc('gender', 1)),
            value: 'gender',
          },
          {
            text: this.$t('DOB'),
            value: 'dob',
          },
          {
            text: this.$tc('Lab Result', 2),
            value: 'lab_results_count',
          },
          {
            text: this.$options.filters.capitalize(this.$tc('prescription', 2)),
            value: 'prescriptions_count',
            sortable: false,
          },
        ]
      },
      tableHeaders () {
        var headers = this.headers
        if (!this.$auth.check({ clients: ['view','edit'] })) {
          headers = headers.filter(x => x.value && x.value != 'client')
          headers.unshift({
            text: this.$t('Patient ID'),
            value: 'client.external_id',
          })
        }
        if (!this.$auth.check({ 'lab results': ['*', 'view','edit'] })) {
          headers = headers.filter(x => x.value && x.value != 'lab_results_count')
        }
        if (!this.$auth.check({ prescriptions: ['*', 'view','edit'] })) {
          headers = headers.filter(x => x.value && x.value != 'prescriptions_count')
        }
        return headers
      },
    },
    watch: {

      options: {
        deep: true,
        handler () {
          this.fetchPatients()
        },
      },
    },
    methods: {
      fetchPatients () {
        this.loading = true
        const url = 'patients'
        const promise = this.axios.get(url, {
          params:
            {
              ...{
              page: this.options.page,
              count: this.options.itemsPerPage,
              sort: this.options.sortBy[0],
              order: this.options.sortDesc[0] ? 'desc' : 'asc',
              search: this.options.search,
            },
              ...this.$route.query,
            },
        })

        return promise.then((response) => {
          if (response.data.last_page < response.data.current_page) {
            this.options.page = response.data.last_page
            this.fetchPatients()
            return
          }
          this.$set(this.options, 'page', response.data.current_page)
          this.$set(this.options, 'itemsPerPage', Number(response.data.per_page))
          this.totalItems = response.data.total
          this.patients = response.data.data
        }).catch(error => {
          this.$toast.error(error.response?.data?.message)
        }).finally(() => {
          this.loading = false
        })
      },
    },
  }
</script>
